<template>
  <div>
    <div class="flex">
      <el-input v-model="search" clearable placeholder="货品名称/货品编号/SKU/库位" class="w300" @keyup.enter.native="queryPageStoreGoods">
        <el-button slot="append" icon="el-icon-search" @click="queryPageStoreGoods"></el-button>
      </el-input>
      <el-button-group style="margin-left: auto">
        <el-tooltip class="item" effect="dark" content="货品上架" placement="top-end">
          <el-button type="primary" icon="el-icon-plus" @click="handleGoodsAdd"></el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="货品导出" placement="top-end">
          <el-button type="primary" icon="el-icon-download" @click="developing"></el-button>
        </el-tooltip>
      </el-button-group>
      <el-radio-group v-model="radio" class="marginLeft10 storeGoodsButtonGroup" @change="handleChange">
        <el-tooltip class="item" effect="dark" content="详情" placement="top-end">
          <el-radio-button :label="10">
            <i class="el-icon-s-unfold"></i>
          </el-radio-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="内容" placement="top-end">
          <el-radio-button :label="9">
            <i class="el-icon-menu"></i>
          </el-radio-button>
        </el-tooltip>
      </el-radio-group>
    </div>
    <el-table v-loading="loading" v-show="radio === 10" class="marginTop10" height="500" :data="goodsData" border :summary-method="getSummaries" ref="table2" show-summary>
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" type="index" label="序号" width="50" />
      <el-table-column align="center" label="图片" width="70">
        <template #default="scope">
          <el-image v-if="scope.row.c_master_url != null" :preview-src-list="[scope.row.c_master_url]" class="w50 h50" :src="scope.row.c_master_url"></el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="c_remark" label="SKU" width="70" sortable/>
      <el-table-column align="center" prop="c_sku" label="货品编码" width="120" sortable/>
      <el-table-column align="center" prop="c_name" label="货品名称" width="180" :show-overflow-tooltip="true" sortable>
        <template slot-scope="scope">
          <div class="hHP wHP flex marginLeft10" style="white-space: break-spaces;">
            <el-link type="primary" @click="handleViewGoods(scope.row.goodsId)">{{ scope.row.c_name }}</el-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="s_unit" label="单位" width="70" />
      <el-table-column align="center" prop="n_price" label="零售价" width="70" sortable>
        <template slot-scope="scope">
          {{ scope.row.n_price ? "￥" + scope.row.n_price : "" }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="n_total" label="实际库存" width="90" sortable>
        <template slot-scope="scope">
          {{ formatTotal(scope.row) }}
        </template>
      </el-table-column>
      <!-- todo 要减去出出库单 -->
      <el-table-column align="center" prop="n_surplus" label="可用库存" width="90" sortable>
        <template slot-scope="scope">
          {{ scope.row.n_surplus }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="c_warehouse" label="存放库位" width="90" sortable/>
      <el-table-column align="center" label="操作" width="150">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="showGoodsInventory(scope.row.goodsId)">往来</el-button>
          <el-button type="text" size="mini" @click="showGoodsInventorySet(scope.row)">设置</el-button>
          <el-button v-if="scope.row.r_warehouse" type="text" size="mini" class="red" @click="offById(scope.row)">下架货品</el-button>
          <el-button v-else type="text" size="mini" class="blue" @click="handleShelvesUp(scope.row)">重新上架</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row v-if="radio === 9" :gutter="10" class="marginTop10" style="height: 500px; overflow: auto;">
      <el-col v-for="(goodsItem, index) in goodsData" :key="index" :span="8">
        <div class="marginBottom10">
          <el-card :body-style="{ padding: '0', height: '135px' }">
            <el-row :gutter="10" class="margin10">
              <el-col :span="5">
                <div class="bg-purple" style="margin-top: 5px">
                  <el-image :src="goodsItem.c_master_url" fit="fit" :preview-src-list="[goodsItem.c_master_url]" style="width: 61px; height: 61px" />
                </div>
                <div class="textCenter"><span style="color: #909399">零售价</span></div>
                <div class="textCenter">{{ goodsItem.n_price }}</div>
              </el-col>
              <el-col :span="19">
                <el-link type="primary" style="display: -webkit-box; -webkit-box-orient: vertical; line-clamp: 2; -webkit-line-clamp: 2; overflow: hidden; text-overflow: ellipsis;font-weight: 800;" @click="handleViewGoods(goodsItem.goodsId)">{{ goodsItem.c_name }}</el-link>
                <div>商品大类 - 商品中类 - 商品小类</div>
                <div><span style="color: #909399">货品SKU:</span> {{ goodsItem.c_sku }}</div>
                <div><span style="color: #909399">所在库位: </span>{{ goodsItem.c_num }}</div>
                <div><span style="color: #909399">可用库存: </span>{{ goodsItem.n_surplus }}</div>
              </el-col>
            </el-row>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="货品上架" :visible.sync="dialogData.dialogVisible" width="60%" :before-close="handleClose" append-to-body>
      <GoodsAdd v-if="dialogData.dialogVisible" :store-data="storeData" @handleClose="handleClose" />
    </el-dialog>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="出入详情" :visible.sync="dialogData.goodsInventoryDialogVisible" width="1100px" append-to-body>
      <goodsInventoryInOut v-if="dialogData.goodsInventoryDialogVisible" :store-id="storeId" :goods-id="goodsId" @handleClose="handleClose"></goodsInventoryInOut>
    </el-dialog>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="货品重新上架" :visible.sync="dialogData.goodsShelvesGoodsDialogVisible" width="360px" append-to-body>
      <goodsShelvesWarehouse v-if="dialogData.goodsShelvesGoodsDialogVisible" :store-id="storeId" :inventory-data="inventoryData" @handleClose="handleClose"></goodsShelvesWarehouse>
    </el-dialog>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="货品设置" :visible.sync="dialogData.goodsInventorySetDialogVisible" width="360px" append-to-body>
      <goodsInventorySet v-if="dialogData.goodsInventorySetDialogVisible" :store-id="storeId" :inventory-data="inventoryData" @handleClose="handleClose"></goodsInventorySet>
    </el-dialog>
  </div>
</template>
<script>
import { goodsSortList, handleTree, exportStoreGoods } from "../../../api/goods/goods";
import GoodsAdd from "@/views/store/goodsInventory/dialog/goodsAdd.vue";
import goodsShelvesWarehouse from "@/views/store/goodsInventory/dialog/goodsShelvesWarehouse.vue";
import goodsInventoryInOut from "@/views/store/goodsInventory/dialog/goodsInventoryInOut.vue";
import goodsInventorySet from "@/views/store/goodsInventory/dialog/goodsInventorySet.vue";
export default {
  components: {
    GoodsAdd,
    goodsInventoryInOut,
    goodsShelvesWarehouse,
    goodsInventorySet
  },
  data() {
    return {
      queryParams: {},
      dialogData: {
        dialogVisible: false,
        goodsInventoryDialogVisible: false,
        goodsShelvesGoodsDialogVisible: false,
        goodsInventorySetDialogVisible: false
      },
      loading: false,
      goodsId: null,
      total: 0,
      radio: 10,
      search: "",
      goodsData: [],
      sortsList: [],
      sortsData: [],
      goods: [],
      storeData: {},
      inventoryData: {},
      storeId: null
    }
  },
  updated() {
    this.$nextTick(() => {
      this.$refs['table2'].doLayout();
    })
  },
  methods: {
    activeAndSearchTabs(storeId) {
      this.storeId = storeId;
      this.queryPageStoreGoods();
      this.fetchGoodSorts();
      // $table.resetHeight();
    },
    showGoodsInventorySet(row) {
      this.inventoryData = row
      this.dialogData.goodsInventorySetDialogVisible = true
    },
    handleGoodsAdd() {
      this.topsAjax.get(`${this.baseUrls.store}/selectById`, { sid: this.storeId }).then((response) => {
        let latitude = response.store.clatitude;
        let longitude = response.store.clongitude;
        this.center = [longitude, latitude];
        this.markers = [{ position: [longitude, latitude] }];
        this.circleCenter = [longitude, latitude];
        this.storeData = response.store;
        this.storeData.regionName = response.regionName;
        this.dialogData.dialogVisible = true
      });
    },
    handleShelvesUp(row) {
      this.inventoryData = row
      this.dialogData.goodsShelvesGoodsDialogVisible = true
    },
    formatTotal(row) {
      return row.n_total < 0 ? 0 : row.n_total
    },
    handleViewGoods(sid) {
      const url = `/goods/goodsInfo?sid=${sid}`;
      window.open(url, "_blank");
    },
    showGoodsInventory(goodsId) {
      this.goodsId = goodsId;
      this.dialogData.goodsInventoryDialogVisible = true;
    },
    queryOrdGoods(orderId) {
      let url = `${this.baseUrls.orderGoods}/queryList`;
      let params = {
        orderId: orderId,
      };
      this.topsAjax.get(url, params).then((response) => {
        this.goods = response;
      });
    },
    closeDialog() {
      this.dialogData.dialogVisible = false
    },
    fetchGoodSorts() {
      goodsSortList().then((response) => {
        this.sortsList = response.data;
        this.sortsData = handleTree(response.data, "sid", "rsort");
      });
    },
    /**
     * 软删除
     * @param row
     */
     offById(row) {
      // if (row.n_total > 0) {
      //   this.$message({
      //     message: "只能下架实际库存为0的商品",
      //     type: "warning",
      //   });
      //   return;
      // }
      this.$confirm('货品下架后将不绑定库位，是否确认下架选定的货品？')
        .then((_) => {
          const url = `${this.baseUrls.inventory}/offWarehouseById`;
          this.topsAjax.post(url, { sid: row.sid }).then(() => {
            this.$message({
              message: "下架成功",
              type: "success",
            });
            this.queryPageStoreGoods();
          });
        })
        .catch((_) => {});
    },
    /**
     * 获取库存信息
     */
    queryPageStoreGoods() {
      this.loading = true
      this.search = this.search.trim();
      this.goodsData = [];
      let url = `${this.baseUrls.store}/queryStoreGoods`;
      let params = this.queryParams;
      params.storeId = this.storeId;
      params.search = this.search;
      this.topsAjax.get(url, params).then((res) => {
        this.loading = false
        this.goodsData = res;
        for (let i = 0; i < this.goodsData.length; i++) {
          if (this.goodsData[i].n_price) {
            this.goodsData[i].n_price = this.goodsData[i].n_price.toFixed(2);
          }  
        }
        this.querySurplus(0);
      }).catch(() => {
        this.loading = false
      });
    },
    querySurplus(index) {
      if (this.goodsData.length <= index) {
        return;
      }
      let ids = '';
      for (let i = index; i < index + 10; i++) {
        if (this.goodsData.length <= i) {
          break;
        }
        if (ids.length > 0) {
          ids += ',';
        }
        ids += this.goodsData[i].inventoryId;
      }

      let url = `${this.baseUrls.store}/querySurplusesSummary`;
      this.topsAjax.get(url, { inventoryIds: ids }).then((res) => {
        for (let j = index; j < index + Object.keys(res).length; j++) {
          let item = {
            ...this.goodsData[j]
          }
          item.n_surplus = res[item.inventoryId]
          this.$set(this.goodsData, j, item)
        }
        this.querySurplus(index + 10)
      });
    },
    handleClose() {
      this.dialogData.goodsShelvesGoodsDialogVisible = false;
      this.dialogData.goodsInventorySetDialogVisible = false;
      this.dialogData.dialogVisible = false;
      this.queryPageStoreGoods();
    },
    /**
     * 切换列表和卡片
     * @param val
     */
    handleChange(val) {
      // this.queryParams.pageSize = val;
      this.queryPageStoreGoods();
    },
    getSummaries(param) {
      const { data } = param;
      const sums = [];
      sums[0] = "合计";
      sums[5] = "货品条数：" + data.length;
      let actual = 0;
      let usable = 0;
      let warehouseNum = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].n_total) {
          actual += parseInt(data[i].n_total);
          usable += parseInt(data[i].n_total - (data[i].n_out ? data[i].n_out : 0) - (data[i].n_ord_out ? data[i].n_ord_out : 0));
        }
        if (data[i].c_warehouse) {
          warehouseNum++;
        }
      }
      sums[5] += ",库位容量：" + warehouseNum
      sums[8] = actual;
      sums[9] = usable;
      return sums;
    },
    developing() {
      this.topsAjax.get(`${this.baseUrls.store}/selectById`, { sid: this.storeId }).then((response) => {
        exportStoreGoods({storeId: response.store.sid, storeName: response.store.cname });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-table .discard-row4 {
  text-decoration: line-through;
}
.allotOrderOut {
  .el-table__header-wrapper .el-checkbox {
    display: none !important;
  }
}
.AddInventoryIn {
  height: 650px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .el-divider--horizontal {
    margin: 8px 0;
  }

  .el-input--medium .el-input__inner {
    height: 20px;
    line-height: 20px;
  }

  .el-input__inner {
    border: 0px;
  }

  &__hideColon {
    .el-descriptions-item__label:not(.is-bordered-label) {
      display: none;
    }
  }

  .el-descriptions__body {
    margin-left: 0px;
  }

  .el-input__icon {
    display: flex !important;
    align-items: center !important;
  }
}
</style>
