<template>
  <!-- 出库制单-->
  <el-dialog class="elDialog" :close-on-click-modal="false" title="期初入库" :visible.sync="implInventoryDialogVisible" width="400px" append-to-body>
  <div style="text-align: center">
    <el-upload ref="upload" :limit="1" accept=".xlsx, .xls" :headers="upload.headers" :action="upload.url + '?storeId=' + storeInfo.sid + '&storeName=' + storeInfo.cname + '&isConver=' + upload.isConver" :disabled="upload.isUploading" :on-progress="handleFileUploadProgress" :on-success="handleFileSuccess" :auto-upload="false" drag>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">点击上传</div>
      <div slot="tip" class="el-upload__tip text-center">
        <div slot="tip" class="el-upload__tip"><el-button type="text" @click="handleExportTemplate">下载模板</el-button>&nbsp;<el-checkbox v-model="upload.isConver" /> 是否删除已经存在的货品数据</div>
      </div>
    </el-upload>
    <div class="flex alignItemsCenter" style="justify-content: space-between; flex: 1">
      <div>
        门店：<span style="color: blue">{{ storeInfo.cname }}</span>
      </div>
      <el-button type="primary" @click="submitFileForm">确定</el-button>
    </div>
  </div>
</el-dialog>
</template>
<script>
import { getToken } from "@/utils/auth";
import clipboard from '@/utils/clipboard.min.js'
import { importInventorySheetTemplate } from "@/api/goods/goods";
export default {
  name: "ImplInventory",
  props: {
    storeId: {
      type: [String, Object, Array], // 根据需要指定数据类型
      default: "1", // 设置一个默认值，如空字符串
    },
  },
  data() {
    return {
      implInventoryDialogVisible: false,
      // 门店信息
      storeInfo: {},
      upload: {
        isUploading: false,
        isConver: true,
        headers: { Authorization: getToken() },
        url: "/api/repository/inventory/implInventory",
      },
    };
  },
  methods: {
    showDialog(storeId) {
      this.getStoreInfo(storeId)
      this.implInventoryDialogVisible = true
    },
    getStoreInfo(id) {
      let url = `${this.baseUrls.store}/get/` + id;
      this.topsAjax.get(url).then((res) => {
        this.storeInfo = res;
      });
    },
    submitFileForm() {
      if (this.upload.isConver) {
        this.$confirm("此操作不可逆，是否继续？")
        .then(() => {
          this.$refs.upload.submit();
        })
        .catch(() => {});
      } else {
        this.$refs.upload.submit();
      }
    },
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
    handleFileSuccess(response, file, fileList) {
      console.log(response);
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      this.$alert("<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" + (response.code === 200 ? response.data : response.message) + "</dev>", "导入结果", 
        { 
          dangerouslyUseHTMLString: true,
          showCancelButton: true,
          showConfirmButton: ((response.code === 200 ? response.data : response.message) !== '导入成功！') ? true : false,
          confirmButtonText: '复制',
          cancelButtonText: '关闭',
          callback: (action) => {
            console.log(action)
            if (action === 'confirm') {
              clipboard.copy(response.code === 200 ? response.data : response.message)
              this.$message({
                type: 'success',
                message: `复制成功`
              });
            }
          }
        });
      this.implInventoryDialogVisible = false
      this.$emit("handleClose");
    },
    handleExportTemplate() {
      importInventorySheetTemplate()
    }
  },
};
</script>
<style lang="scss" scope>
.addDeliveryNote {
  height: 620px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .el-divider--horizontal {
    margin: 8px 0;
  }
  .el-input--medium .el-input__inner {
    height: 20px;
    line-height: 20px;
  }
  .el-input__inner {
    border: 0px;
  }
  //隐藏图标
  .el-input__icon {
    display: none;
  }
}
</style>
